import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {NgxSpinnerModule} from 'ngx-spinner';
import {FormsModule} from '@angular/forms';
import {PermissionPipePipe} from "./permission-pipe.pipe";
import {ColumnTotalPipe} from "./column-total.pipe";
import {PrettyPrintPipe} from "./pretty-print.pipe";
import { OptionConvertTextPipe } from './option-convert-text.pipe';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxSpinnerModule,
    FormsModule
  ],
    exports: [
        PermissionPipePipe,
        ColumnTotalPipe,
        PrettyPrintPipe,
        OptionConvertTextPipe
    ],
  declarations: [
    PermissionPipePipe,
    ColumnTotalPipe,
    PrettyPrintPipe,
    OptionConvertTextPipe
  ]
})
export class PipeModule { }
