import { BrowserModule } from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SharedModule} from './shared/shared.module';
import {MenuItems} from './shared/menu-items/menu-items';
import {BreadcrumbsComponent} from './layout/admin/breadcrumbs/breadcrumbs.component';
import {AuthService} from './theme/auth/auth.service';
import {AuthGuard} from './theme/auth/auth-guard.service';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {PipeModule} from "./pipes/pipe.module";
import {DeviceDetectorModule} from "ngx-device-detector";
// import { SelectionQueueComponent } from './queue/selection-queue/selection-queue.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    // SelectionQueueComponent,


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    CommonModule,
    ToastrModule.forRoot(),
    PipeModule,
    DeviceDetectorModule.forRoot()
  ],
  schemas: [],
  providers: [MenuItems, AuthService, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
