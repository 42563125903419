import {EventEmitter, Injectable, Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import {GlobleService} from '../global/globle.service';

@Injectable({
  providedIn: 'root'
})
export class QuesService {
  private baseURL = environment.url;
  constructor(
    private http: HttpClient,
    private globleService: GlobleService
  ) { }


  getMenu() {
    return this.http.get(this.baseURL + "get-menu", this.globleService.getHttpOptions());
  }

  getHttpOptions() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
        'Request-Year': localStorage.getItem('year')
      })
    };
  }

  getDataForm(ques_id, status, gsDivisionRef) {
    return this.http.get(this.baseURL + 'ques' + ques_id + '/get-data/' + status + '/' + gsDivisionRef, this.getHttpOptions());
  }

  postFromData(ques_id, data) {
    return this.http.post(this.baseURL + 'ques' + ques_id + '/save-data', JSON.stringify(data),  this.globleService.getHttpOptions());
  }

  getSearchQuestion(bookNo: any) {
    return this.http.get(this.baseURL + 'search-questions/'+ bookNo, this.getHttpOptions());
  }

  getAllQuestions() {
    return this.http.get(this.baseURL + 'get-all-questions', this.getHttpOptions());
  }

  getGsDivision(user_ref: string) {
    return this.http.get(this.baseURL + 'get-gs-division/'+user_ref, this.getHttpOptions());
  }

  getRecalGsDivision(question_id) {
    return this.http.get(this.baseURL + 'recall_gs_division/'+question_id, this.getHttpOptions());
  }

  getGsDivisionWithOutData() {
    return this.http.get(this.baseURL + 'auth/get-divition-without-data', this.getHttpOptions());
  }
}
