import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'optionConvertText'
})
export class OptionConvertTextPipe implements PipeTransform {

    transform(option_value:any,option_type: number): any {
        if(option_type == 1){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතා දුර්වලයි";
                case 2:
                    return "දුර්වලයි";
                case 3:
                    return "තරමක් හොඳයි";
                case 4:
                    return "හොඳයි";
                case 5:
                    return "ඉතා හොඳයි";
                default:
                    return "නැත";
            }
        }
        else if(option_type == 2){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඔව්";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 3){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඇත";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 4){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතා කලාතුරකින් දක්නට ලැබීම";
                case 2:
                    return "කලාතුරකින් දක්නට ලැබීම";
                case 3:
                    return "සමානයේන දක්නට ලැබීම";
                case 4:
                    return "සුලබව දක්නට ලැබීම";
                case 5:
                    return "ඉතා සුලබව දක්නට ලැබීම";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 5){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතා කලාතුරකින් හානිකරයි";
                case 2:
                    return "කලාතුරකින් හානිකරයි";
                case 3:
                    return "සාමාන්‍යයෙන් හානිකරයි";
                case 4:
                    return "ඉහළ හානියක් කරයි";
                case 5:
                    return "ඉතා ඉහළ හානියක් කරයි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 6){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 10";
                case 2:
                    return "11-50 අතර";
                case 3:
                    return "51-100 අතර";
                case 4:
                    return "101-300 අතර";
                case 5:
                    return "300 ට වැඩි ";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 7){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතා දුර්ලභයි";
                case 2:
                    return "දුර්ලභයිි";
                case 3:
                    return "සාමාන්‍යයෙන් දක්නට ලැබේ";
                case 4:
                    return "සුලබයි";
                case 5:
                    return "ඉතා සුලබයි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 8){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 10";
                case 2:
                    return "11-20";
                case 3:
                    return "21-30";
                case 4:
                    return "31-40";
                case 5:
                    return "40 ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 9){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 5";
                case 2:
                    return "6-10";
                case 3:
                    return "11-15";
                case 4:
                    return "16-20";
                case 5:
                    return "20 ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 10){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "වනයෙන් ආවරණ වී  ඇත";
                case 2:
                    return "පුරාවිදයා වටිනාකමක් පවතී";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 11){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ගිණි තැබීම";
                case 2:
                    return "අනවසර අල්ලා ගැනීම්";
                case 3:
                    return "හේන් ගොවිතැන";
                case 4:
                    return "මහා පරිමාණ ගව පාලනය";
                case 5:
                    return "නීතිවිරෝදී දැව කැපීම";
                case 6:
                    return "නීතිවිරෝදී මත්පැන් නිෂ්පාදනය";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 12){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "කිසිවක් නොදනී";
                case 2:
                    return "දැනුවත්  නැත";
                case 3:
                    return "සාමානයෙන් දැනුවත්";
                case 4:
                    return "දැනුවත්";
                case 5:
                    return "බොහෝදුරට දැනුවත්";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 13){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "රැස් නොවේ";
                case 2:
                    return "මාසයකට වරක්";
                case 3:
                    return "තෙමසකට වරක්";
                case 4:
                    return "මාස හයකට වරක්";
                case 5:
                    return "වසරකට වරක්";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 14){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ලැව් ගින්නක්";
                case 2:
                    return "ගිනි තැබීමක්";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 15){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 10";
                case 2:
                    return "11-50";
                case 3:
                    return "51- 100";
                case 4:
                    return "101- 150";
                case 5:
                    return "150 ට වැඩි";
                default:
                    return "නැත";
            }
        }
        else if(option_type == 16){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 5";
                case 2:
                    return "5ට වැඩි -10";
                case 3:
                    return "11-20 අතර";
                case 4:
                    return "21-30 අතර";
                case 5:
                    return "30 ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 17){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඔව්";
                case 2:
                    return "තරමක් දුරට දැනුවත්ය";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 18){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "1 සිට 5 දක්වා";
                case 2:
                    return "6 සිට 10 දක්වා";
                case 3:
                    return "10 සිට  20 දක්වා";
                case 4:
                    return "21 සිට 40 දක්වා";
                case 5:
                    return "41 සිට 50 දක්වා";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 19){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි -20%";
                case 2:
                    return "21%-40%";
                case 3:
                    return "41%-60%";
                case 4:
                    return "61%-80%";
                case 5:
                    return "80%ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 200){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි -20";
                case 2:
                    return "21-40";
                case 3:
                    return "41-60";
                case 4:
                    return "61-80";
                case 5:
                    return "80ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 20){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 1";
                case 2:
                    return "2 – 5";
                case 3:
                    return "6 – 9";
                case 4:
                    return "10 – 15";
                case 5:
                    return "15 ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 21){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 70";
                case 2:
                    return "71-200";
                case 3:
                    return "201-500";
                case 4:
                    return "501 - 1000";
                case 5:
                    return "1000 ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 22){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 100";
                case 2:
                    return "101 - 200";
                case 3:
                    return "201 - 300";
                case 4:
                    return "301 - 400";
                case 5:
                    return "400ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 23){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ගෙවත්තෙන්";
                case 2:
                    return "හේන්";
                case 3:
                    return "ගෙවත්ත හා හේන් මගින්";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 24){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 25000";
                case 2:
                    return "25001 – 50000";
                case 3:
                    return "50001- 75000";
                case 4:
                    return "75001- 100000";
                case 5:
                    return "100000 ට වැඩි";
                default:
                    return "නැත";
            }

        } else if(option_type == 25){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි -10";
                case 2:
                    return "11-15";
                case 3:
                    return "16-20";
                case 4:
                    return "21-25";
                case 5:
                    return "25ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 26){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 100";
                case 2:
                    return "101 – 200";
                case 3:
                    return "201- 300";
                case 4:
                    return "301- 400";
                case 5:
                    return "400 ට වැඩි";
                default:
                    return "නැත";
            }

        } else if(option_type == 27){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ගෙවත්තෙන්";
                case 2:
                    return "හේන්";
                case 3:
                    return "ගෙවත්ත හා හේන් මගින්";
                case 4:
                    return "කුඹුරු";
                default:
                    return "නැත";
            }

        }else if(option_type == 28){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි -25";
                case 2:
                    return "26 - 50";
                case 3:
                    return "51 – 75";
                case 4:
                    return "76 – 100";
                case 5:
                    return "100 ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 29){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 05";
                case 2:
                    return "06 – 20";
                case 3:
                    return "21 – 35";
                case 4:
                    return "36 – 50";
                case 5:
                    return "50 ට වැඩි";
                default:
                    return "නැත";
            }

        }else  if(option_type == 30){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතා දුර්වලයි";
                case 2:
                    return "දුර්වලයි";
                case 3:
                    return "සාමාන්‍යයි";
                case 4:
                    return "හොඳයි";
                case 5:
                    return "ඉතා හොඳයි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 31){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි  – 05";
                case 2:
                    return "06 – 10";
                case 3:
                    return "11 – 15";
                case 4:
                    return "16 – 20";
                case 5:
                    return "20ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 32){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ගබඩාව";
                case 2:
                    return "අලෙවි සැල";
                case 3:
                    return "ධාන්‍ය එකතු කිරීමේ මධ්‍යස්ථාන";
                case 4:
                    return "වෙනත් ද්‍රව්‍ය එකතු කිරීමේ මධ්‍යස්ථාන";
                case 5:
                    return "සේවා මධ්‍යස්ථාන";
                case 6:
                    return "එළවළු එකතු කිරීමේ මධ්‍යස්ථානය";
                case 7:
                    return "කිරි එකතු කිරීමේ මධ්‍යස්ථානය";
                default:
                    return "නැත";
            }
        }else  if(option_type == 33){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ගොවීන් ගෙනවිත් දීම";
                case 2:
                    return "මධ්‍යස්ථානයේ නිළධාරීන් වගා බිම වෙතින් ලබා ගැනීම";
                case 3:
                    return "අතරමැදියන් මගින් ලබා ගැනීම";
                case 4:
                    return "වවෙනත්";
                default:
                    return "නැත";
            }
        }else  if(option_type == 34){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 - 200";
                case 2:
                    return "201 - 300";
                case 3:
                    return "301 - 400";
                case 4:
                    return "401 - 500";
                case 5:
                    return "501 ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 35){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 - 5000";
                case 2:
                    return "5001 - 10000";
                case 3:
                    return "10001 - 20000";
                case 4:
                    return "20001 - 30000";
                case 5:
                    return "30000 ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 36){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 - 5";
                case 2:
                    return "6 - 10";
                case 3:
                    return "11 - 20";
                case 4:
                    return "21 - 50";
                case 5:
                    return "50 ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 37){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතා දුර්වලයි";
                case 2:
                    return "දුර්වලයි";
                case 3:
                    return "සාමාන්‍යයි";
                case 4:
                    return "හොඳයි";
                case 5:
                    return "ඉතා හොඳයි";
                // default:
                //     return "නැත";
            }
        }else  if(option_type == 38){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return " 0 ට වැඩි - 10";
                case 2:
                    return "11 - 30";
                case 3:
                    return "31 - 50";
                case 4:
                    return "51 - 70";
                case 5:
                    return "70ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 39){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return " 0 ට වැඩි - 4";
                case 2:
                    return "5 - 6";
                case 3:
                    return "7 - 8";
                case 4:
                    return "9 - 10";
                case 5:
                    return "10ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 40){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return " මධ්‍යස්ථාන වෙත";
                case 2:
                    return "ගෙනගොස් බාරදීම";
                case 3:
                    return "අතරමැදියන් වෙත ලබාදීම";
                case 4:
                    return "නිවසේ දීම විකිණීම";
                default:
                    return "නැත";
            }
        }else  if(option_type == 41){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "යෝගට්";
                case 2:
                    return "මුදවපු කිරි";
                case 3:
                    return "ඟිතෙල්";
                case 4:
                    return "වෙනත්";
                default:
                    return "නැත";
            }
        }else  if(option_type == 42){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 400";
                case 2:
                    return "401-500";
                case 3:
                    return "501-600";
                case 4:
                    return "601-700";
                case 5:
                    return "700ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 43){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 100";
                case 2:
                    return "101-300";
                case 3:
                    return "301-500";
                case 4:
                    return "501-700";
                case 5:
                    return "700 ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 44){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 - 10";
                case 2:
                    return "11 – 50";
                case 3:
                    return "51 – 90";
                case 4:
                    return "91 – 130";
                case 5:
                    return "131 ට වැඩි";
                default:
                    return "නැත";
            }
        }else if(option_type == 45){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 40";
                case 2:
                    return "41 – 60";
                case 3:
                    return "61 – 80";
                case 4:
                    return "81 – 100";
                case 5:
                    return "100 ට වැඩිි";
                default:
                    return "නැත";
            }

        }else if(option_type == 46){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 75";
                case 2:
                    return "76 – 150";
                case 3:
                    return "151 – 225";
                case 4:
                    return "226 – 300";
                case 5:
                    return "301 ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 47){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0ට වැඩි - 3";
                case 2:
                    return "4 - 7";
                case 3:
                    return "7 - 10";
                case 4:
                    return "11 - 14";
                case 5:
                    return "14 වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 48){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 25";
                case 2:
                    return "26 - 50";
                case 3:
                    return "51 - 75";
                case 4:
                    return "76 - 100";
                case 5:
                    return "100ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 49){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි -50";
                case 2:
                    return "51-75";
                case 3:
                    return "76-100";
                case 4:
                    return "101-125";
                case 5:
                    return "125 ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 50){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0% ට වැඩි - 4%";
                case 2:
                    return "5%  - 8%";
                case 3:
                    return "9%  - 12%";
                case 4:
                    return "13% - 16%";
                case 5:
                    return "16%ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 51){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "දෛනික";
                case 2:
                    return "සති වාරික";
                case 3:
                    return "මාසික";
                case 4:
                    return "ත්‍රෛ මාසික";
                case 5:
                    return "අර්ධ වාර්ෂික";
                case 6:
                    return "වාර්ෂික";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 52){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "නිවාස ණය";
                case 2:
                    return "පාරිභෝගික ණය";
                case 3:
                    return "ගොවි ණය";
                case 4:
                    return "අඩු ආදායම් ලාභී ණය(සමෘද්ධි)";
                case 5:
                    return "ස්වයං රැකියා ණය";
                case 6:
                    return "විදේශ රැකියා ණය";
                case 7:
                    return "ස්වශක්ති ණය";
                case 8:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 53){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "රාජ්‍ය";
                case 2:
                    return "අර්ධ රාජ්‍ය";
                case 3:
                    return "රාජ්‍ය නොවන";
                case 4:
                    return "පෞද්ගලික";
                case 5:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 54){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉතුරුම් පහසුකම්";
                case 2:
                    return "ස්වයංක්‍රීය ගනුදෙනු පහසුකම් (යන්ත්‍ර)";
                case 3:
                    return "පුද්ගලික/ නිවාස/  ව්‍යාපාරික ණය පහසුකම්";
                case 4:
                    return "ලීසිං පහසුකම්";
                case 5:
                    return "විශේෂ දිනුම් ඇදීම්, ත්‍යාග ලබාදීම් ආදිය";
                case 6:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 55){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0% ට වැඩි - 20%";
                case 2:
                    return "21% – 40%";
                case 3:
                    return "41% – 60%";
                case 4:
                    return "61% – 80%";
                case 5:
                    return "80% ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 56){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "බීජ";
                case 2:
                    return "පොහොර";
                case 3:
                    return "පළිබෝධ නාශක";
                case 4:
                    return "ජල පද්ධති";
                case 5:
                    return "යන්ත්‍රෝපකරණ";
                case 6:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 57){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 20";
                case 2:
                    return "21 - 50";
                case 3:
                    return "51 - 80";
                case 4:
                    return "81 - 110";
                case 5:
                    return "110ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 58){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "රාජ්‍ය";
                case 2:
                    return "අර්ධ රාජ්‍ය";
                case 3:
                    return "රාජ්‍ය නොවන";
                case 4:
                    return "පෞද්ගලික";
                case 5:
                    return "ප්‍රජාමූල සංවිධාන";
                case 6:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 59){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "පුහුණු";
                case 2:
                    return "පර්යේෂණ";
                default:
                    return "නැත";
            }

        }else if(option_type == 60){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "25% ට අඩු";
                case 2:
                    return "25% - 50%";
                case 3:
                    return "50% - 75%";
                case 4:
                    return "75% - 100";
                case 5:
                    return "100ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 61){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "සතියකට වරක්";
                case 2:
                    return "සති දෙකකට වරක්";
                case 3:
                    return "මාසයකට වරක්";
                case 4:
                    return "මාස තුනකට වරක්";
                default:
                    return "නැත";
            }

        }else if(option_type == 62){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ප්‍රදේශයේ රාජ්‍ය ආයතන සතුව පවතී";
                case 2:
                    return "ප්‍රදේශයේ පුද්ගලික ආයතන සතුව පවතී";
                case 3:
                    return "වෙනත් ප්‍රදේශවල පුහුණු මධ්‍යස්ථාන වෙත යොමු කළ යුතුය";
                default:
                    return "නැත";
            }

        }else if(option_type == 63){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "අවට පරිසරයෙනි";
                case 2:
                    return "ප්‍රදේශයේ සැපයුම්කරුවන් මඟින්";
                case 3:
                    return "වෙනත් ප්‍රදේශවල සැපයුම්කරුවන් මඟින්";
                default:
                    return "නැත";
            }

        }else if(option_type == 64){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "තමාම නිෂ්පාදනය කිරීම";
                case 2:
                    return "අතරමැදියන්ගෙන් ලබා ගැනීම";
                case 3:
                    return "මධ්‍යස්ථානවලට සෘජුව ගොස් ලබා ගැනීම";
                default:
                    return "නැත";
            }

        }else if(option_type == 65){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ප්‍රාදේශීය බැංකු මඟිනි";
                case 2:
                    return "රාජ්‍ය නොවන ආයතන මඟිනි";
                case 3:
                    return "රජයේ වැඩසටහන් හරහා චක්‍රීය ණය පහසුකම්";
                case 4:
                    return "එවැනි පහසුකම් නොමැත";
                default:
                    return "නැත";
            }

        }else if(option_type == 66){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 2";
                case 2:
                    return "3 - 5";
                case 3:
                    return "6 - 8";
                case 4:
                    return "9 - 11";
                case 5:
                    return "11ට වැඩි";
                default:
                    return "නැත";
            }

        }else  if(option_type == 67){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ප්‍රමාණවත් නොවේ";
                case 2:
                    return "දුර්වලයි";
                case 3:
                    return "සාමාන්‍යයි";
                case 4:
                    return "හොඳයි";
                case 5:
                    return "ඉතා හොඳයි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 68){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "උදේ පමණි";
                case 2:
                    return "දහවල් පමණි";
                case 3:
                    return "උදේ සහ සවස පමණි";
                case 4:
                    return "උදේ සිට සවස 6 දක්වා";
                case 5:
                    return "උදේ සිට රාත්‍රී 12 දක්වා";
                case 6:
                    return "24 පැය පුරා";
                default:
                    return "නැත";
            }
        }else  if(option_type == 69){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ප්‍රමාණවත්";
                case 2:
                    return "ප්‍රමාණවත් නොවේ";
                default:
                    return "නැත";
            }
        } else if(option_type == 70){
            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 50";
                case 2:
                    return "51-100";
                case 3:
                    return "101-150";
                case 4:
                    return "151-200";
                case 5:
                    return "200ට වැඩි";
                default:
                    return "නැත";
            }

        }
        else if(option_type == 71){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 10";
                case 2:
                    return "11-50";
                case 3:
                    return "51-100";
                case 4:
                    return "101-150";
                case 5:
                    return "150ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 72){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "මහවැලි";
                case 2:
                    return "වාරිමාර්ග";
                case 3:
                    return "පළාත් වාරිමාර්ග";
                case 4:
                    return "ගොවිජන සේවා";
                case 5:
                    return "වන ජීවී/වන සංරක්ෂණ";
                default:
                    return "නැත";
            }

        }else if(option_type == 73){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "අතහැරදමා ඇත";
                case 2:
                    return "ඉතා දුර්වලයි";
                case 3:
                    return "දුර්වලයි";
                case 4:
                    return "සාමාන්‍යයි";
                case 5:
                    return "හොඳයි";
                case 6:
                    return "ඉතා හොඳයි";
                default:
                    return "නැත";
            }

        }else if(option_type == 74){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "වැව් ජලය";
                case 2:
                    return "මහවැලි";
                case 3:
                    return "ප්‍රධාන ජලාශයෙන්";
                case 4:
                    return "වැසිජලය";
                case 5:
                    return "පෝෂිත ඇල මාර්ගය";

                default:
                    return "නැත";
            }

        }else if(option_type == 75){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "1 ක් ඇත";
                case 2:
                    return "2 ක් ඇත";
                case 3:
                    return "2ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 76){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "කොන්ක්‍රීට්";
                case 2:
                    return "පස්";
                case 3:
                    return "දෙකම";
                default:
                    return "නැත";
            }

        }else if(option_type == 77){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "වාර්ෂයකට වරක්";
                case 2:
                    return "වසරකට දෙවරක්";
                case 3:
                    return "වසර 5කට වරක්";
                case 4:
                    return "වසර 10කට වරක්";
                default:
                    return "නැත";
            }

        }else if(option_type == 78){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "රොන්මඩ පිරීම";
                case 2:
                    return "ජලජ පැලෑටි බහුල වීම";
                case 3:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 79){

            switch (parseInt(option_value)) {
                case 0:
                    return "නැත";
                case 1:
                    return "බැම්ම";
                case 2:
                    return "සොරොව්ව";
                case 3:
                    return "වාන හා වාන් ඇල";
                case 4:
                    return "ඇල වේලි";
                case 5:
                    return "අපවහන ඇල";
                case 6:
                    return "සියළු කොටස්";
                case 7:
                    return "කෘෂි මාර්ග";
                case 8:
                    return "අමුණ";

                default:
                    return "නැත";
            }

        }else if(option_type == 80){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "වාර 1";
                case 2:
                    return "වාර 2";
                case 3:
                    return "වාර 2ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 81){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return " 0 ට වැඩි - 100";
                case 2:
                    return "101-500";
                case 3:
                    return "501-900";
                case 4:
                    return "901-1300";
                case 5:
                    return "1300ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 82){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 20000";
                case 2:
                    return "20000- 40000";
                case 3:
                    return "40000-60000";
                case 4:
                    return "80000 - 100000";
                case 5:
                    return "100000 ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 83){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 1";
                case 2:
                    return "2-4";
                case 3:
                    return "5-7";
                case 4:
                    return "8-10";
                case 5:
                    return "10ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 84){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "අළෙවිය ඉහළයි";
                case 2:
                    return "සාමාන්‍යයි";
                case 3:
                    return "අළෙවිය පහළයි";
                default:
                    return "නැත";
            }

        }else if(option_type == 85){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "අළෙවිය ඉහළයි";
                case 2:
                    return "සාමාන්‍යයි";
                case 3:
                    return "අළෙවිය පහළයි";
                default:
                    return "නැත";
            }

        }else if(option_type == 86){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "SLS";
                case 2:
                    return "ISO";
                case 3:
                    return "GMP";
                default:
                    return "නැත";
            }

        }else if(option_type == 87){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "ඉහළ";
                case 2:
                    return "සාමාන්‍යයි";
                case 3:
                    return "පහළ";
                default:
                    return "නැත";
            }

        }else if(option_type == 88){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "පුද්ගලිකව සපයා ගැනීම";
                case 2:
                    return "බාහිරින් සපයාගැනීම";
                default:
                    return "නැත";
            }

        }else if(option_type == 89){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 50";
                case 2:
                    return "51-100";
                case 3:
                    return "101-200";
                case 4:
                    return "201-300";
                case 5:
                    return "300 ට වැඩි ";
                default:
                    return "නැත";
            }

        }else if(option_type == 90){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "මහ නගර සභාව";
                case 2:
                    return "ප්‍රාදේශීය සභාව";
                case 3:
                    return "වන ජීවී දෙපාර්තමේන්තුව";
                default:
                    return "නැත";
            }

        }else if(option_type == 91){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 50";
                case 2:
                    return "51-100";
                case 3:
                    return "101-500";
                case 4:
                    return "501-1000";
                case 5:
                    return "1000ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 92){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 10";
                case 2:
                    return "11 - 25";
                case 3:
                    return "26 - 40";
                case 4:
                    return "41 - 55";
                case 5:
                    return "55ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 93){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "සිංහල";
                case 2:
                    return "දෙමල";
                case 3:
                    return "ඉංග්‍රිසි";
                case 4:
                    return "චින";
                case 5:
                    return "කොරියා";
                case 6:
                    return "ජර්මන්";
                case 7:
                    return "ප්‍රංශ";
                case 8:
                    return "වෙනත්";
                default:
                    return "නැත";
            }

        }else if(option_type == 94){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "කි.මී ½ට අඩු";
                case 2:
                    return "කි.මි ½-1අතර";
                case 3:
                    return "කි.මි 1-2අතර";
                case 4:
                    return "කි.මි 2-3 අතර";
                case 5:
                    return "කි.මී 3ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 95){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "පැය 1 ට අඩු";
                case 2:
                    return "පැය 1 - 2අතර";
                case 3:
                    return "පැය 2-3 අතර";
                case 4:
                    return "පැය 3-5 අතර";
                case 5:
                    return "පැය 5 වැඩි";
                default:
                    return "නැත";
            }

        } else if(option_type == 96){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 5";
                case 2:
                    return "6-20 අතර";
                case 3:
                    return "21-30අතර";
                case 4:
                    return "31-40අතර";
                case 5:
                    return "40ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 97){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "බටහිර";
                case 2:
                    return "ආයුර්වේද";
                case 3:
                    return "බටහිර හා ආයුර්වේද";
                case 4:
                    return "පාරම්පරික";
                case 5:
                    return "ශාන්තිකර්ම";
                default:
                    return "නැත";
            }

        } else if(option_type == 98){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return " 0 ට වැඩි - 40";
                case 2:
                    return "41-60";
                case 3:
                    return "61-80";
                case 4:
                    return "81-100";
                case 5:
                    return "100ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 99){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "රජයේ";
                case 2:
                    return "පෞද්ගලික";
                case 3:
                    return "සමිතියක් සතු";
                default:
                    return "නැත";
            }

        }else if(option_type == 100){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 10";
                case 2:
                    return "11-30";
                case 3:
                    return "31-50";
                case 4:
                    return "61-80";
                case 5:
                    return "80ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 101){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි - 1";
                case 2:
                    return "2-4";
                case 3:
                    return "5-6";
                case 4:
                    return "7-8";
                case 5:
                    return "8ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 102){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0ට වැඩි - 2";
                case 2:
                    return "3-5";
                case 3:
                    return "6-8";
                case 4:
                    return "9-11";
                case 5:
                    return "11ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 103){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "50ට අඩු";
                case 2:
                    return "51 -75";
                case 3:
                    return "76 – 100";
                case 4:
                    return "101 –125";
                case 5:
                    return "126 ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 104){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0-20";
                case 2:
                    return "20-30";
                case 3:
                    return "30-40";
                case 4:
                    return "40-50";
                case 5:
                    return "50ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 105){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "2ට අඩු";
                case 2:
                    return "2-5";
                case 3:
                    return "6-9";
                case 4:
                    return "10-13";
                case 5:
                    return "13ට වැඩි";
                default:
                    return "නැත";
            }

        }else if(option_type == 106){
            switch (option_value) {
                case 0:
                    return "ලබා  නොදේ";
                case 1:
                    return "ලබා දේ";
                default:
                    return "ලබා  නොදේ";
            }
        }else if(option_type == 107){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "5ට අඩු";
                case 2:
                    return "5-10";
                case 3:
                    return "11-15";
                case 4:
                    return "115-20";
                case 5:
                    return "20ට වැඩි";
                default:
                    return "නැත";
            }
        }else if(option_type == 108){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "විදුලියෙන් ක්‍රියාකරන";
                case 2:
                    return "ඉන්ධන භාවිතයෙන් ක්‍රියාකරන (ඩීසල්, පෙට්‍රල්)";
                default:
                    return "නැත";
            }
        }else if(option_type == 109){
            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "5ට අඩු";
                case 2:
                    return "6 - 11";
                case 3:
                    return "12 - 15";
                case 4:
                    return "16 - 20";
                case 5:
                    return "20ට වැඩි";
                default:
                    return "නැත";
            }
        }else  if(option_type == 110){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "0 ට වැඩි  – 5ට අඩු";
                case 2:
                    return "06 – 10";
                case 3:
                    return "11 – 15";
                case 4:
                    return "16 – 20";
                case 5:
                    return "20ට වැඩි";
                default:
                    return "නැත";
            }
        }else if(option_type == 111){

            switch (option_value) {
                case 0:
                    return "නැත";
                case 1:
                    return "50ට අඩු";
                case 2:
                    return "50 – 100";
                case 3:
                    return "101 – 150";
                case 4:
                    return "151 – 200";
                case 5:
                    return "200 ට වැඩි";
                default:
                    return "නැත";
            }

        }

    }


}
