import { Pipe, PipeTransform } from '@angular/core';
import {element} from "protractor";

@Pipe({
  name: 'prettyprint'
})
export class PrettyPrintPipe implements PipeTransform {

  transform(val,type) {
console.log(val);
console.log(type);

    let html ="";
   val.forEach(event => {
     if(type ==1){
       html += "<li>";
       html += event['product_type'];
       html += "</li>";
     }else if (type == 2){
       html += "<li>";
       html += event['avarage_product'];
       html += "</li>";
     }else if (type == 3){
       html += "<li>";
       html += event['annual_income'];
       html += "</li>";
     }

    });

    return html
    // return JSON.stringify(val, undefined, 4)
    //   .replace(/ /g, '&nbsp;')
    //   .replace(/\n/g, '<br/>');
  }


}
