import {EventEmitter, Injectable, Output} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../environments/environment';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class GlobleService {
  @Output() incomingRequestEvent: EventEmitter<string> = new EventEmitter();
  private baseURL = environment.url;
  constructor(public toastr: ToastrService,private http:HttpClient ) { }

  // notification change
  incomingRequestChange(count) {
    this.incomingRequestEvent.emit(count);
  }

  getAPIURL() {
    return environment.url;
  }

  getSuccessNotifyOptions(message: any) {
    this.toastr.success(message, 'Success!');
  }

  getErrorNotifyOptions(message: any) {
    this.toastr.error(message, 'Fail!');
  }

  swalSuccess($message) {
    swal('සාර්ථකයි!', $message, 'success');
  }

  swalPrimary($message) {
    swal('Question Type', $message, 'question');
  }

  swalInfo($message) {
    swal('Info Type', $message, 'info');
  }

  swalWarning($message) {
    swal('Warning Type', $message, 'warning');
  }

  swalDanger($message) {
    swal('අසාර්ථකයි', $message, 'error');
  }

  checkHasPermission(permission_ref) {

    let permissions = JSON.parse(localStorage.getItem('permissions'));
    let found = permissions.filter(x => x.permission_ref == permission_ref);
    if (found.length != 0)
      return true;

    if (permission_ref == '') // Temporary. Remove on Live
      return true;

    return false;
  }

  getEventfulAppKey() {
    return "j2gpqsQT6t6T8DbJ";
  }

  getHttpOptions() {
    return  {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
        'Request-Year': localStorage.getItem('year')
      })
    };
  }
  setFunctioCode(functionCode)
  {
    return this.http.get(environment.url + "function-usage"+"/"+functionCode,this.getHttpOptions());
  }

  uploadFile (data: File[]){

    let httpOption = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+ localStorage.getItem('authToken')
      })
    };
    return this.http.post(this.baseURL + "upload/file", data, httpOption);
  }

  getUserVillagesRequest(){
      return this.http.get(environment.url + "user/villages", this.getHttpOptions());
  }

  getLocationBySection(section) {
    return this.http.get(this.baseURL + 'get-locations?section=' + section, this.getHttpOptions());
  }

  getReports(data) {
    return this.http.post(this.baseURL + 'get-reports', data, this.getHttpOptions());
  }

  getHttpOptionsBlob() {
      return {
        responseType: 'blob' as 'json',
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('authToken')
        })
      };
    }

  getLevelMenu() {
    return this.http.get(this.baseURL + 'level-menu', this.getHttpOptions());
  }

  getSDivisionByDID(district_id: any, type) {
    return this.http.get(this.baseURL + 'get-locations-by-id?type='+type+'&id=' + district_id, this.getHttpOptions());
  }
}
