import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { HttpHeaders } from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class AuthService {
  token: string;
  httpOptions: any;
  baseUrl = environment.url;
  constructor(private http: HttpClient ) {

    this. httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    };
  }

  signupUser(email: string, password: string) {
    // your code for signing up the new user
  }

  signinUser(data) {
    return this.http.post(this.baseUrl + "auth/login", data);
  }

  logout() {
    this.token = null;
    this. httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
      })
    };

    return this.http.get(this.baseUrl + 'auth/logout', this.httpOptions);

  }

  getToken() {
    return this.token;
  }

  setToken(token) {
    this.token = token;
    return this.token;
  }


  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    // if (this.token === null || this.token == '' ) {
    //   return false;
    // }else{
    //   console.log('true');
    //   return true;
    // }

    this.token = localStorage.getItem('authToken');
    if (this.token === null || this.token == '') {
      return false;
    } else {
      return true;
    }


  }
}
