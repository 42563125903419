import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'permissionPipe'
})
export class PermissionPipePipe implements PipeTransform {

  transform(value: any, permission: string): any {
    let permissions = JSON.parse(localStorage.getItem('permissions'));
    if(permission == 'true') {
      return true;
    }else{
      let index = permissions.filter(x => x.p_code == permission);
      if (index.length == 0)
        return false;
      return true;
    }
  }

}
