import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import {AuthGuard} from './theme/auth/auth-guard.service';
// import {ProfileModule} from "./profile/profile.module";
// import {UserRegisterModule} from "./users/user-register/user-register.module";

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: '',
        loadChildren: './reports/reports.module#ReportsModule'
      },
      {
        path: '',
        loadChildren: './users/user-register/user-register.module#UserRegisterModule'
      },
      {
        path: '',
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: '',
        loadChildren: './queue/queue.module#QueueModule'
      },
      {
        path: '',
        loadChildren: './question-rows/question-rows.module#QuestionRowsModule'
      },
      {
        path: '',
        loadChildren: './questions/ques_1_100/ques1-100.module#Ques1100Module'
      },
      {
        path: 'questions',
        loadChildren: './questions/ques_1_100/ques1-100.module#Ques1100Module'
      },
      {
        path: 'questions',
        loadChildren: './questions/ques_101_200/ques101-200.module#Ques101200Module'
      },
      {
        path: 'questions',
        loadChildren: './questions/ques_201_300/ques201-300.module#Ques201300Module'
      },
      {
        path: 'questions',
        loadChildren: './questions/ques_301_400/ques301-400.module#Ques301400Module'
      },
      {
        path: 'dashboard',
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'main-dashboard',
        loadChildren: './main-dashboard-chart-view/main-dashboard.module#MainDashboardModule'
      },
      {
        path: 'chart',
        loadChildren: './theme/chart/chart.module#ChartModule'
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'login',
        loadChildren: './theme/auth/login/basic-login/basic-login.module#BasicLoginModule'
      },
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'maintenance/error',
        loadChildren: './theme/maintenance/error/error.module#ErrorModule'
      },
      {
        path: 'maintenance/coming-soon',
        loadChildren: './theme/maintenance/coming-soon/coming-soon.module#ComingSoonModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren: './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      },
      {
        path: 'email/email-template',
        loadChildren: './theme/email/email-template/email-template.module#EmailTemplateModule'
      },
      {
        path: 'landing',
        loadChildren: './theme/landing/landing.module#LandingModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
