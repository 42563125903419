import { Pipe, PipeTransform } from '@angular/core';
import {element} from "protractor";

@Pipe({
  name: 'columnTotal'
})
export class ColumnTotalPipe implements PipeTransform {

  transform(value: any, columnName: string): any {
    let valueTotal: number = 0;
    if(value !== undefined){
      value.forEach(element => {
        let currentCount =  element.data[columnName];
        if(currentCount == '' || currentCount == null)
          currentCount = 0;
        valueTotal  = valueTotal + parseInt(currentCount);
      });
    }
    return valueTotal;
  }

}
